<template>
  <div>
    <v-footer v-show="showFooter"
              ref="footer"
              :color="!$vuetify.theme.dark ? 'bg-light' : '#fbf9f6'">
      <v-col class="text-right" cols="12">
        © Copyright {{ new Date().getFullYear() }}
        <strong class="text-primary">Gifty</strong> Tous droits réservés.
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {
    computed: {
        showFooter() {
            let path_name = this.$route.name;
            return !["e_payment.message", "e_payment.checkout"].includes(path_name);
        },
    }
}
</script>

<style scoped>

</style>